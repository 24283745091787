import en from 'i18n/translations/en';
import md from 'i18n/translations/md';
import ru from 'i18n/translations/ru';

const translations = {
  en: {
    translation: en,
  },
  md: {
    translation: md,
  },
  ru: {
    translation: ru,
  },
};

export default translations;
