import Page from '../layout/Page';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useContentfulCar} from '../../hooks/useCars';
import CarContainer from '../car/CarContainer';
import './_car_page.scss';
import CarCarousel from '../car/CarCarousel';
import CarPricelistTable from '../car/CarPricelistTable';
import {Trans} from 'react-i18next';
import Form from "../contact-us/Form";


const CarPage = () => {
  const params = useParams();
  const {index} = params;
  const idx = index - 1;
  const {car} = useContentfulCar(idx);

  if (!car) {
    return null
  }

  return (
    <Page>
      <div className="car-page-title">
        <p>{car.fields.name}</p>
      </div>
      <div className="car-page">
        <div className="card-container">
          <CarContainer index={idx} hideActions />
        </div>
        <div className="car-price-table">
          <CarPricelistTable data={car.fields.price.fields.pricing} />
          <div className="car-contact-form">
            <Form />
          </div>
        </div>
        <div className="car-carousel">
          <p className="car-section-title">
            <Trans i18nKey="header.gallery" />
          </p>
          <CarCarousel images={car.fields.images.map(image => `https:${image.fields.file.url}`)} />
        </div>
      </div>
    </Page>
  );
};

export default CarPage;
