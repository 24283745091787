import React from 'react';
import {Route, Routes as BaseRoutes} from 'react-router-dom';
import Home from './pages/Home';
import CarPage from './pages/CarPage';
import CarsPage from './pages/CarsPage';
import GalleryPage from 'component/pages/GalleryPage';

const Routes = () => (
  <BaseRoutes>
    <Route path="/cars" element={<CarsPage />} />
    <Route path="/cars/:index" element={<CarPage />}/>
    <Route path="/gallery" element={<GalleryPage />}/>
    <Route path="/" element={<Home />} />
  </BaseRoutes>
);

export default Routes;
