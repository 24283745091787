import React from 'react';

const price1 = (delta = 0) => [
  {
    days: '1',
    price: `${25 + delta}€`,
  },
  {
    days: '≥2',
    price: `${20 + delta}€`,
  },
  {
    days: '≥4',
    price: `${18 + delta}€`,
  },
  {
    days: '≥8',
    price: `${16 + delta}€`,
  },
  {
    days: '≥22',
    price: `${15 + delta}€`,
  },
  {
    days: '≥35',
    price: `${14 + delta}€`,
  },
];

const price2 = (delta = 0) => [
  {
    days: '1',
    price: `${30 + delta}€`,
  },
  {
    days: '≥2',
    price: `${25 + delta}€`,
  },
  {
    days: '≥4',
    price: `${23 + delta}€`,
  },
  {
    days: '≥8',
    price: `${20 + delta}€`,
  },
  {
    days: '≥22',
    price: `${18 + delta}€`,
  },
  {
    days: '≥35',
    price: `${16 + delta}€`,
  },
];

const price3 = (delta = 0) => [
  {
    days: '1',
    price: `${32 + delta}€`,
  },
  {
    days: '≥2',
    price: `${27 + delta}€`,
  },
  {
    days: '≥4',
    price: `${25 + delta}€`,
  },
  {
    days: '≥8',
    price: `${23 + delta}€`,
  },
  {
    days: '≥22',
    price: `${20 + delta}€`,
  },
  {
    days: '≥35',
    price: `${18 + delta}€`,
  },
]

const CarsContext = React.createContext([
  {
    image: '/assets/image/cars/reno-grand-scenic-gray-1.jpeg',
    title: 'Renault Grand Scenic',
    people: 7,
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.minivan',
    engine: 'detail.engine.diesel',
    vol: '1.5L',
    price: price3(),
    consumption: '4.5 - 6.0 l/100km',
    images: [
      '/assets/image/cars/reno-grand-scenic-gray-1.jpeg',
      '/assets/image/cars/reno-grand-scenic-gray-2.jpeg',
      '/assets/image/cars/reno-grand-scenic-gray-3.jpeg',
      '/assets/image/cars/reno-grand-scenic-gray-4.jpeg',
    ],
  },
  {
    image: '/assets/image/cars/reno-scenic-white-1.jpeg',
    title: 'Renault Scenic',
    transmission: 'detail.transmission.auto',
    bodyType: 'detail.body.minivan',
    engine: 'detail.engine.diesel',
    vol: '1.5L',
    price: price3(),
    consumption: '4.5 - 6.0 l/100km',
    images: [
      '/assets/image/cars/reno-scenic-white-1.jpeg',
    ],
  },
  {
    image: '/assets/image/cars/opel-asta-j-white-1.jpeg',
    title: 'Opel Astra J',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.hatchback',
    engine: 'detail.engine.diesel',
    vol: '1.3L',
    price: price2(),
    consumption: '4.5 - 6.0 l/100km',
    images: [
      '/assets/image/cars/opel-asta-j-white-1.jpeg',
    ],
  },
  {
    image: '/assets/image/cars/reno-scenic-black-1.jpeg',
    title: 'Renault Scenic',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.minivan',
    engine: 'detail.engine.diesel',
    vol: '1.5L',
    price: price2(),
    consumption: '4.5 - 6.0 l/100km',
    images: [
      '/assets/image/cars/reno-scenic-black-1.jpeg',
    ],
  },
  {
    image: '/assets/image/cars/opel-asta-h-blue-1.jpeg',
    title: 'Opel Astra H',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.universal',
    engine: 'detail.engine.diesel',
    vol: '1.3L',
    price: price1(),
    consumption: '4.2 - 5.7 l/100km',
    images: [
      '/assets/image/cars/opel-asta-h-blue-1.jpeg',
      '/assets/image/cars/opel-asta-h-blue-2.jpeg',
      '/assets/image/cars/opel-asta-h-blue-3.jpeg',
    ],
  },
  {
    image: '/assets/image/cars/opel-asta-h-hatchback-1.jpeg',
    title: 'Opel Astra H',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.hatchback',
    engine: 'detail.engine.diesel',
    vol: '1.3L',
    price: price1(),
    consumption: '4.2 - 5.7 l/100km',
    images: [
      '/assets/image/cars/opel-asta-h-hatchback-1.jpeg',
    ],
  },
  {
    title: 'Renault Megane',
    image: '/assets/image/cars/reno-megane-hatchback-gray-1.jpeg',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.hatchback',
    engine: 'detail.engine.diesel',
    vol: '1.5L',
    price: price2(),
    consumption: '4.5 - 6.0 l/100km',
    images: [
      '/assets/image/cars/reno-megane-hatchback-gray-1.jpeg',
      '/assets/image/cars/reno-megane-hatchback-gray-2.jpeg',
      '/assets/image/cars/reno-megane-hatchback-gray-3.jpeg',
    ],
  },
  {
    title: 'Opel Astra H',
    image: '/assets/image/cars/opel-asta-h-blue-universal-1.jpeg',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.universal',
    engine: 'detail.engine.diesel',
    vol: '1.3L',
    price: price1(),
    consumption: '4.2 - 5.7 l/100km',
    images: [
      '/assets/image/cars/opel-asta-h-blue-universal-1.jpeg',
      '/assets/image/cars/opel-asta-h-blue-universal-2.jpeg',
    ],
  },
  {
    image: '/assets/image/cars/opel-universal-black2.jpg',
    title: 'Opel Astra J',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.universal',
    engine: 'detail.engine.diesel',
    vol: '1.3L',
    price: price2(),
    consumption: '4.2 - 5.7 l/100km',
    images: [
      '/assets/image/cars/opel-universal-black1.jpg',
      '/assets/image/cars/opel-universal-black2.jpg',
    ],
  }, {
    image: '/assets/image/cars/opel-hatchback-gray.jpg',
    title: 'Opel Astra H',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.hatchback',
    engine: 'detail.engine.diesel',
    vol: '1.3L',
    price: price1(),
    consumption: '4.2 - 6.4 l/100km',
    images: [
      '/assets/image/cars/opel-hatchback-gray.jpg',
    ],
  }, {
    image: '/assets/image/cars/opel-hatchback-blue.jpg',
    title: 'Opel Astra H',
    transmission: 'detail.transmission.auto',
    bodyType: 'detail.body.hatchback',
    engine: 'detail.engine.diesel',
    vol: '1.3L',
    price: price1(2),
    consumption: '4.2 - 6.4 l/100km',
    images: [
      '/assets/image/cars/opel-hatchback-blue.jpg',
    ]
  }, {
    image: '/assets/image/cars/megane-universal-gray1.jpg',
    title: 'Renault Megane',
    transmission: 'detail.transmission.auto',
    bodyType: 'detail.body.universal',
    engine: 'detail.engine.diesel',
    vol: '1.5L',
    price: price2(2),
    consumption: '4.5 - 6.0 l/100km',
    images: [
      '/assets/image/cars/megane-universal-gray1.jpg',
    ],
  }, {
    image: '/assets/image/cars/megane-hatchback-silver1.jpg',
    title: 'Renault Megane',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.hatchback',
    engine: 'detail.engine.diesel',
    vol: '1.5L',
    consumption: '4.5 - 5.8 l/100km',
    price: price2(),
    images: [
      '/assets/image/cars/megane-hatchback-silver1.jpg',
      '/assets/image/cars/megane-hatchback-silver2.jpg',
      '/assets/image/cars/megane-hatchback-silver3.jpg',
    ],
  }, {
    image: '/assets/image/cars/fiat-punto.jpg',
    title: 'Fiat Grande Punto',
    transmission: 'detail.transmission.auto',
    bodyType: 'detail.body.hatchback',
    engine: 'detail.engine.gas-benzin',
    vol: '1.4L',
    price: price1(2),
    consumption: '5.2 - 8.0 l/100km',
    images: [
      '/assets/image/cars/fiat-punto.jpg',
    ],
  }, {
    image: '/assets/image/cars/opel-astra-j-blue4.jpg',
    title: 'Opel Astra J',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.universal',
    engine: 'detail.engine.diesel',
    vol: '1.3L',
    price: price2(),
    consumption: '4.2 - 5.7 l/100km',
    images: [
      '/assets/image/cars/opel-astra-j-blue1.jpg',
      '/assets/image/cars/opel-astra-j-blue2.jpg',
      '/assets/image/cars/opel-astra-j-blue3.jpg',
      '/assets/image/cars/opel-astra-j-blue4.jpg',
      '/assets/image/cars/opel-astra-j-blue5.jpg',
      '/assets/image/cars/opel-astra-j-blue6.jpg',
      '/assets/image/cars/opel-astra-j-blue7.jpg',
    ],
  }, {
    image: '/assets/image/cars/reno-grand-scenic1.jpg',
    title: 'Renault Grand Scenic',
    transmission: 'detail.transmission.manual',
    bodyType: 'detail.body.minivan',
    engine: 'detail.engine.diesel',
    vol: '1.5L',
    price: price2(),
    people: 5,
    consumption: '4.5 - 6.0 l/100km',
    images: [
      '/assets/image/cars/reno-grand-scenic1.jpg',
      '/assets/image/cars/reno-grand-scenic2.jpg',
    ],
  }, {
    image: '/assets/image/cars/opel-hatchback-silver-benzin.jpg',
    title: 'Opel Astra H',
    transmission: 'detail.transmission.auto',
    bodyType: 'detail.body.hatchback',
    engine: 'detail.engine.benzin',
    vol: '1.4L',
    price: price1(2),
    consumption: '4.4 - 6.6 l/100km',
    images: [
      '/assets/image/cars/opel-hatchback-silver-benzin.jpg',
    ],
  },
]);

export default CarsContext;
