import React from "react";

function SvgFuel() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 1000 1000">
      <path
        d="M4526.1 5008.9c-1477.9-151.4-2792.8-943.1-3626.6-2187.1-306.7-460-594.2-1144.3-703.5-1683-82.4-414-97.8-563.5-95.8-1025.5 0-500.3 34.5-785.9 149.5-1226.8 90.1-343.1 178.3-580.8 356.5-939.2 983.3-1987.8 3181.9-3068.8 5357.5-2633.7 1623.6 325.8 2997.9 1481.7 3605.5 3036.2C9772.5-1126.9 9876-624.7 9897-40c46 1326.4-446.6 2605-1374.4 3563.4-772.4 797.3-1727 1288-2836.8 1458.6-239.6 36.5-914.4 53.7-1159.7 26.9zm1083-776.3c1341.8-203.2 2516.8-1061.9 3111-2275.3 193.6-392.9 302.9-739.9 381.5-1201.9 57.5-341.2 57.5-923.9 0-1265.1-134.2-789.7-439-1454.9-939.2-2051-531-634.5-1303.4-1123.3-2104.7-1332.2-383.4-99.7-636.4-130.3-1061.9-130.3-425.5 0-678.5 30.7-1061.9 130.3C3134.6-3684 2364-3199 1827.3-2560.7c-1259.4 1497-1316.9 3622.8-139.9 5194.6 174.4 233.9 563.5 623 797.4 797.4 621 463.9 1353.3 753.3 2118.1 831.9 231.8 23 758.9 7.7 1006.2-30.6z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M4919 3233.9c-15.3-5.7-47.9-28.8-70.9-49.8-44.1-38.3-44.1-40.3-44.1-676.6 0-350.8 5.7-651.7 11.5-669 15.3-36.4 105.4-86.3 161-86.3 23 0 67.1 24.9 97.8 55.6l55.6 55.6v615.3c0 343.1-7.7 634.5-19.2 661.3-9.6 26.8-44.1 61.3-74.8 78.6-61.3 30.6-70.9 32.6-116.9 15.3zM3347.2 2812.2c-15.3-5.8-47.9-28.8-70.9-49.8-84.3-74.8-65.2-128.4 235.8-647.9 153.3-266.4 293.3-494.5 310.5-509.9 53.7-40.2 153.3-30.7 205.1 23 86.3 84.3 70.9 128.4-233.9 655.6-302.8 523.2-339.2 567.3-446.6 529zM6385.4 2808.4c-21.1-9.6-51.8-32.6-69-51.8-57.5-65.2-573.1-973.7-573.1-1010.2 0-49.8 51.8-141.9 86.3-155.3 57.5-21.1 139.9-11.5 178.3 23 32.6 26.8 467.7 762.9 567.4 958.4 46 88.2 24.9 168.7-51.8 216.6-67.2 40.4-80.6 42.3-138.1 19.3zM2197.2 1662.1c-69-24.9-115-90.1-115-159.1 0-47.9 13.4-82.4 44.1-109.3 23-23 199.4-132.3 392.9-243.4C2906.4 926 2962 910.7 3040.6 995.1c49.8 53.7 61.3 159.1 23 207-42.2 49.8-807 483-833.8 471.5-2 0-17.3-5.7-32.6-11.5zM7545.1 1662.1c-70.9-26.8-757.1-433.2-780.2-461.9-40.2-53.7-30.7-153.3 23-205.1 82.4-84.3 136.1-67.1 546.3 170.6 396.8 230 435.1 266.4 412.1 377.6-17.2 78.6-130.3 145.7-201.2 118.8zM6456.3 251.3c-176.3-118.8-531-360.4-789.7-534.8L5197-601.7l-57.5 28.8c-88.2 47.9-293.3 55.6-408.3 17.2-224.3-74.7-377.6-293.3-375.7-538.6 0-164.9 47.9-279.9 161-392.9 113.1-113.1 228.1-161 392.9-161 331.6-3.8 582.7 262.6 561.6 594.2l-7.7 122.7L6235.8-408c849.2 573.1 856.8 578.9 812.7 724.6C7019.7 412.4 6946.9 468 6853 468c-63.1-.1-124.5-32.7-396.7-216.7zM1794.6-270v-661.3h864.5l-5.8 120.8-5.7 118.8-272.2 5.8-274.1 5.7v302.8l245.3 5.8 243.4 5.8 5.7 120.8 5.8 118.8H2101.2V142.1h517.6v249.2h-824.2V-270zM7372.6-270v-661.3h306.6v536.6h479.2v228.1l-233.9 5.8-235.8 5.8L7683-5.5l-5.8 147.6h519.5v249.2h-824.2V-270zM3788.1-2733.2V-2944h421.8v421.6H3788.2v-210.8zM4784.9-2733.2V-2944H5206.6v421.6H4784.9v-210.8zM5781.6-2733.2V-2944H6203.3v421.6H5781.6v-210.8z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default SvgFuel;
