import CarsContext from '../context/CarsContext';
import {useContext} from 'react';
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {contentfulClient} from "../shared/contentful";

const useCars = () => useContext(CarsContext);

const getContentfulCars = async () => {
  const {items} = await contentfulClient.getEntries({
    content_type: 'car',
    order: '-fields.name,sys.createdAt',
    include: 10,
  })

  return items;
}

/**
 * @returns {UseQueryResult<Array<import('../../types').TypeCar>, unknown>}
 */
export const useContentfulCars = () => {
  return useQuery({
    queryKey: ['contentful-cars'],
    queryFn: getContentfulCars,
    placeholderData: [],
    staleTime: Infinity,
  })
}

/**
 * @param index
 * @returns {{car: import('../../types').TypeCar | undefined, isLoading: boolean}}
 */
export const useContentfulCar = (index) => {
  const {data, isLoading} = useContentfulCars();

  return {
    car: data?.[index],
    isLoading,
  };
}

export default useCars;
