import * as React from "react";

export const SvgPhone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 30.888 30.888"
    }}
    viewBox="0 0 30.888 30.888"
    {...props}
  >
    <path
      d="M23.494 18.708a1.98 1.98 0 0 0-1.412-.586h-.001c-.535 0-1.037.208-1.415.586l-2.828 2.828-8.485-8.485 2.828-2.828c.378-.378.586-.881.585-1.416 0-.534-.209-1.037-.586-1.414L7.938 3.15a1.987 1.987 0 0 0-1.413-.585h-.001c-.535 0-1.037.209-1.414.587L.866 7.395c-.435.435-3.925 4.561 7.071 15.557 6.499 6.499 10.598 7.937 12.953 7.937 1.63 0 2.426-.689 2.604-.867l4.241-4.241c.378-.377.587-.879.587-1.414a1.99 1.99 0 0 0-.586-1.417l-4.242-4.242zm-1.414 9.899c-.029.028-3.41 2.249-12.729-7.07-9.52-9.52-7.067-12.729-7.071-12.729l4.243-4.244 4.243 4.244-3.535 3.535a.999.999 0 0 0 0 1.414l9.899 9.899a.999.999 0 0 0 1.414 0l3.534-3.536 4.243 4.244-4.241 4.243z" />
    <path
      d="M28.888 0h-12c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h5v3a1.002 1.002 0 0 0 1.707.707L27.302 13h1.586c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2zm0 11h-2a.997.997 0 0 0-.707.293l-2.293 2.293V12a1 1 0 0 0-1-1h-6V2h12v9z" />
    <path d="M18.888 4h8v2h-8zm0 3h8v2h-8z" />
  </svg>
);
