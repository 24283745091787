import React from 'react';

const SvgBaby = () => (
  <svg version="1.1" id="baby" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 461.413 461.413"xmlSpace="preserve">
<g>
	<path d="M405.696,225.029l-83.082-12.453c-13.584-2.036-26.97,4.738-33.375,16.889l-8.712,16.528
		c17.614-4.418,34.018-7.292,48.549-7.292c33.65,0,56.273,15.842,61.403,49.394l5.636,0.845
		c17.654,2.646,34.101-9.518,36.746-27.166C435.507,244.126,423.344,227.675,405.696,225.029z"/>
	<path d="M157.812,153.469c-5.108,2.715-9.567,6.168-13.343,10.143c10.131,22.015,18.531,51.522,25.293,74.933
		c2.865,9.92,9.753,18.406,18.898,23.284c5.567,2.969,11.843,4.539,18.15,4.539c3.981,0,7.922-0.614,11.714-1.826
		c17.203-5.497,33.669-10.799,49.332-15.179l-29.768-56.004l68.291-50.785c14.32-10.649,17.296-30.891,6.646-45.211
		c-10.648-14.32-30.892-17.295-45.211-6.646l-77.104,57.339C179.83,146.269,168.3,147.894,157.812,153.469z"/>
	<path d="M329.076,262.642c-26.809,0-64.712,12.39-103.267,24.706c-6.202,1.981-12.611,2.961-18.999,2.961
		c-10.164,0-20.276-2.48-29.416-7.356c-14.885-7.939-25.952-21.558-30.633-37.765c-15.522-53.742-31.811-110.073-60.557-102.899
		c-47.06,11.745-70.162,81.558-51.6,155.933c9.797,39.255,29.243,71.929,52.342,92.312c2.434,2.57,5.081,5.008,7.906,7.324
		c-0.9,6.546-6.513,47.367-7.422,53.973c-0.697,5.068,3.247,9.583,8.355,9.583h179.134c5.115,0,9.051-4.522,8.355-9.583
		c-0.687-4.993-5.51-40.069-6.15-44.723c61.627-26.565,99.742-76.356,88.47-121.524
		C361.413,268.826,348.011,262.642,329.076,262.642z"/>
	<path d="M118.253,127.233c51.562,8.246,91.539-46.299,66.58-93.254C168.242,2.763,129.485-9.091,98.269,7.501
		S55.199,62.85,71.792,94.066C81.429,112.196,98.835,124.128,118.253,127.233z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
);

export default SvgBaby;