import React from 'react';
import Page from '../layout/Page';
import CarsContainer from '../car/CarsContainer';
import "./_cars_page.scss";
import {Trans, withTranslation} from 'react-i18next';

const CarsPage = () => {
  return (
    <Page className="ric-cars-page">
      <p className="title">
        <Trans i18nKey="header.ourcars" />
      </p>
      <CarsContainer />
    </Page>
  )
};

export default withTranslation()(CarsPage);
