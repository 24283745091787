import React, {useMemo} from 'react';
import Header from './layout/Header';
import ScrollPosition from './ingest/ScrollPosition';
import Footer from './layout/Footer';
import Routes from './Routes';
import {useLocation} from 'react-router-dom';
import ScrollToTop from './ingest/ScrollToTop';
import {Slide, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient();

const Root = () => {
  const {pathname} = useLocation();
  const isHome = useMemo(() => pathname === '/', [pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop/>
      <ScrollPosition>
        {({top}) => (
          <Header isClear={!isHome || top > window.innerHeight - 48} />
        )}
      </ScrollPosition>
      <Routes />
      <ToastContainer transition={Slide} />
      <Footer />
    </QueryClientProvider>
  )
};

export default Root;
