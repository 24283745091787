import {CarCard} from './CarCard';
import React, {useCallback} from 'react';
import {useContentfulCar} from '../../hooks/useCars';
import {useNavigate} from 'react-router-dom';

const CarContainer = ({index, hideActions = false}) => {
  const {car} = useContentfulCar(index)
  const navigate = useNavigate();
  const onDetailClick = useCallback(() => {

    navigate(`/cars/${index + 1}`);
  }, [navigate, index]);

  if (!car) {
    return null;
  }

  return (
    <CarCard
      vol={car.fields.engineSize}
      transmission={car.fields.transmission}
      engine={car.fields.engine}
      people={car.fields.peopleCount}
      consumption={car.fields.consumption}
      bodyType={car.fields.bodyType}
      image={car.fields.images[0].fields.file.url}
      title={car.fields.name}
      hideActions={hideActions}
      onDetailClick={onDetailClick}
    />
  )
};

export default CarContainer;
