import React, {Fragment} from 'react';
import Background from '../layout/Background';
import Description from '../home/Description';
import Section from '../layout/Section';
import Trait from '../home/Trait';
import CarsContainer from '../car/CarsContainer';
import SvgClock from '../svg/SvgClock';
import SvgBaby from '../svg/SvgBaby';
import SvgMap from '../svg/SvgMap';
import SvgMoney from '../svg/SvgMoney';
import {useTranslation} from 'react-i18next';

const traits = t => [
  {
    icon: <SvgClock />,
    title: t('section.24/7'),
    description: t('section.24/7.description'),
  },
  {
    icon: <SvgBaby />,
    title: t('section.child_seat'),
    description: t('section.child_seat.description'),
  },
  {
    icon: <SvgMoney />,
    title: t('section.save_your_money'),
    description: t('section.save_your_money.description'),
  },
];

const Home = () => {
  const {t} = useTranslation();

  return (
    <Fragment>
      <Background>
        <Description />
      </Background>
      <Section title={t('section.q_choose_us')}>
        {traits(t).map((traitDetails, index) => (
          <Trait {...traitDetails} key={index} />
        ))}
      </Section>
      <Section title={t('header.ourcars')}>
        <CarsContainer />
      </Section>
      {/*<Section title={"Want to rent a car?"}>*/}
      {/*  <div className="col-2-of-3">*/}
      {/*    <RentForm />*/}
      {/*  </div>*/}
      {/*</Section>*/}
    </Fragment>
  )
};

export default Home;
