import CarCarousel from 'component/car/CarCarousel';
import Page from 'component/layout/Page';
import React, {useMemo} from 'react';
import {Trans} from 'react-i18next';
import './_car_page.scss';
import {useContentfulCars} from 'hooks/useCars';

const GalleryPage = () => {
  const {data} = useContentfulCars();
  const images = useMemo(
    () => data.flatMap(car => car.fields.images.map(image => `https:${image.fields.file.url}`)),
    [data],
  );

  return (
    <Page>
      <div className="car-page-title">
        <p><Trans i18nKey="header.gallery" /></p>
      </div>
      <CarCarousel images={images} />
    </Page>
  )
};

export default GalleryPage;
