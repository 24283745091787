import React from 'react';
import './_page.scss';

const Page = ({children, className}) => (
  <div className={`ric-page ${className || ''}`}>
    {children}
  </div>
);

export default Page;
