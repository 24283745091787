import React from "react";
import { CarDetail } from "./CarDetail";
import SvgTransmission from "../svg/SvgTransmission";
import SvgEngine from "../svg/SvgEngine";
import SvgSedan from "../svg/SvgSedan";
import { Button } from "../form/Button";
import SvgPeople from "../svg/SvgPeople";
import "./_car-card.scss";
import { useTranslation } from "react-i18next";
import SvgFuel from "component/svg/SvgFuel";

const CarCard = ({
  image,
  title,
  transmission,
  vol,
  engine,
  bodyType,
  hideActions,
  people,
  consumption,
  onDetailClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="ric-car-card">
      <div className="car-card-image" onClick={onDetailClick}>
        <img alt={title} src={image} />
      </div>
      <div className="car-card-title">{title}</div>
      <div className="car-card-details">
        <CarDetail
          svgIcon={<SvgTransmission />}
          name={t("detail.transmission")}
          value={t(transmission)}
        />
        <CarDetail
          svgIcon={<SvgEngine />}
          name={t("detail.engine")}
          value={`${vol} ${t(engine)}`}
        />
        <CarDetail
          svgIcon={<SvgSedan />}
          name={t("detail.body")}
          value={t(bodyType)}
        />
        <CarDetail
          svgIcon={<SvgPeople />}
          name={t("detail.people")}
          value={people || 5}
        />
        <CarDetail
          svgIcon={<SvgFuel />}
          name={t("detail.consumption")}
          value={consumption}
        />
        {/*<CarDetail svgIcon={<SvgCalendar/>} name={t('detail.year')} value="2010"/>*/}
      </div>
      {!hideActions && (
        <div className="card-actions">
          <Button
            onClick={onDetailClick}
            text={
              <div>
                <i className="fas fa-search-plus" /> {t("detail.details")}
              </div>
            }
            className="card-btn"
          />
          {/*<Button text={<div><i className="far fa-handshake"/> Rent</div>} className="card-btn" theme="blue" />*/}
        </div>
      )}
    </div>
  );
};

export { CarCard };
