import React, {Component} from 'react';
import './footer.scss';

export default class Footer extends Component {
  render() {
    return (
      <div className="ric-footer" id="contacts">
        <div className="footer-body">
          <div className="footer-social">
            <div className="social-links">
              <a className="footer-link social-link" href="viber://add?number=37369315097">
                <i className="fab fa-viber" /> Viber
              </a>
              <a className="footer-link social-link" href="https://api.whatsapp.com/send?phone=37369315097">
                <i className="fab fa-whatsapp" /> WhatsApp
              </a>
              <a className="footer-link social-link" href="tel:+37369315097">
                <i className="fa fa-phone" /> &nbsp;+373 69 315 097
              </a>
              <a className="footer-link social-link" href="tel:+37369274285">
                <i className="fa fa-phone" /> &nbsp;+373 69 274 285
              </a>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          Copyright © 2020 by AllesRentCars. All rights reserved.
        </div>
      </div>
    );
  }
}
