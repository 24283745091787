import React from 'react';
import './_description.scss';
import {Trans} from 'react-i18next';

const Description = () => {
  return (
    <div className="ric-description">
      <h1 className="description-title">
        <Trans i18nKey="home.title" />
      </h1>

      <div className="description-body">
        <Trans i18nKey="home.description" />
      </div>
    </div>
  )
};

export default Description;
