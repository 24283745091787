import React from 'react';

const SvgClock = () => (
  <svg version="1.1" id="clock" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="612.001px" height="612.001px" viewBox="0 0 612.001 612.001">
<g>
	<path d="M201.582,398.487c0-57.43,74.473-66.617,74.473-98.255c0-13.901-10.759-22.212-24.221-22.212
		c-18.613,0-28.715,18.851-28.715,18.851l-21.536-14.357c0,0,14.576-31.857,52.717-31.857c28.039,0,51.822,17.042,51.822,47.109
		c0,51.147-71.331,59.896-72.007,89.962h74.473v24.897H202.934C202.258,407.475,201.582,402.981,201.582,398.487z M324.809,353.845
		l68.646-100.503h34.322v94.219h20.642v24.021h-20.642v41.045h-28.495v-41.045h-74.473V353.845L324.809,353.845z M399.281,347.561
		v-49.337c0-7.854,1.132-16.604,1.132-16.604h-0.457c0,0-3.58,8.512-7.854,14.576l-36.789,50.927v0.438H399.281L399.281,347.561z
		 M539.777,339.118l-5.543,19.855C502.727,471.835,387.76,543.053,274.033,514.826C164.799,487.716,96.911,381.87,113.235,274.033
		l32.914,9.188c7.629,2.13,14.704-4.798,12.733-12.471L123.088,131.37c-1.97-7.672-11.506-10.334-17.166-4.792L3.097,227.26
		c-5.66,5.542-3.198,15.134,4.433,17.264l35.826,10.001C16.62,400.396,106.611,545.678,253.463,583.919
		c152.489,39.71,308.024-54.678,350.394-206.45l5.299-18.982c1.528-5.474-1.67-11.149-7.144-12.678l-49.557-13.835
		C546.981,330.445,541.305,333.645,539.777,339.118z M598.336,252.268l-51.97,6.752c-5.636,0.732-9.61,5.894-8.879,11.53
		l2.048,15.762c0.732,5.636,5.895,9.611,11.53,8.878l51.97-6.752c5.636-0.732,9.61-5.894,8.879-11.53l-2.049-15.762
		C609.134,255.511,603.972,251.536,598.336,252.268z M558.328,154.064l-45.655,25.729c-4.951,2.79-6.703,9.065-3.913,14.017
		l7.803,13.847c2.79,4.951,9.065,6.703,14.017,3.913l45.655-25.729c4.951-2.79,6.703-9.065,3.913-14.017l-7.803-13.847
		C569.555,153.026,563.279,151.274,558.328,154.064z M484.447,77.998l-32.694,40.958c-3.546,4.441-2.818,10.917,1.623,14.461
		l12.423,9.916c4.441,3.546,10.916,2.819,14.461-1.623l32.694-40.958c3.546-4.442,2.818-10.917-1.623-14.462l-12.423-9.916
		C494.467,72.83,487.992,73.556,484.447,77.998z M387.45,35.147L372.48,85.37c-1.624,5.446,1.476,11.178,6.921,12.801l15.233,4.54
		c5.445,1.624,11.177-1.476,12.801-6.921l14.97-50.223c1.623-5.446-1.476-11.177-6.922-12.801l-15.232-4.54
		C394.805,26.602,389.073,29.701,387.45,35.147z M281.464,31.752l4.933,52.174c0.535,5.658,5.556,9.811,11.213,9.276l15.824-1.497
		c5.658-0.535,9.811-5.555,9.276-11.213l-4.934-52.174c-0.535-5.658-5.556-9.81-11.213-9.275l-15.824,1.496
		C285.082,21.074,280.929,26.094,281.464,31.752z M181.924,68.308l24.119,46.526c2.615,5.045,8.826,7.015,13.872,4.399l14.111-7.314
		c5.045-2.616,7.015-8.826,4.399-13.872l-24.119-46.526c-2.615-5.045-8.826-7.015-13.872-4.399l-14.111,7.315
		C181.278,57.051,179.309,63.262,181.924,68.308z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
);

export default SvgClock;
