import React, { useMemo, useState } from "react";
import "./form.scss";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import cn from "classnames";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { SvgWhatsApp } from "../svg/SvgWhatsapp";
import SvgViber from "../svg/SvgViber";
import { SvgTelegram } from "../svg/SvgTelegram";
import { SvgPhone } from "../svg/SvgPhone";


function Form() {
  const [name, setName] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date(Date.now() + 3600 * 1000 * 24));
  const [dateTo, setDateTo] = useState(new Date(Date.now() + 86400000 * 7));
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [contactMethod, setContactMethod] = useState("");

  const minDate = useMemo(() => new Date(), []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const messageParts = [message];

    if (contactMethod) {
      messageParts.push(`Metoda de contaact: ${contactMethod}`);
    }

    setIsLoading(true);

    const data = {
      name,
      message: messageParts.join("\n"),
      dateFrom: dateFrom.toLocaleDateString(),
      dateTo: dateTo.toLocaleDateString(),
      phone,
      link: window.location.href
    };

    try {
      await fetch(
        "https://rm2ficg3uhmqzdi4pz6in34yzu0wexsh.lambda-url.us-east-1.on.aws/",
        {
          body: JSON.stringify(data),
          method: "POST",
          mode: "no-cors"
        }
      );

      toast(t("contact_form.success_message"), {
        autoClose: 5000,
        position: "bottom-center",
        hideProgressBar: true
      });
    } catch {
      toast("An error occurred", {
        autoClose: 5000,
        position: "bottom-center",
        type: "error"
      });
    }

    setIsLoading(false);
  };

  const isValid = Boolean(phone && name) && !isLoading;

  return (
    <form className="contact-us-form" onSubmit={handleSubmit}>
      <div className="form-title">
        <h2 className="text-2xl">{t("contact_form.title")}</h2>
      </div>
      <div className="form-input-group">
        <label>{t("contact_form.name")}</label>
        <input value={name} onChange={(event) => setName(event.target.value)} />
      </div>
      <div className="form-input-group">
        <label>{t("contact_form.from_date")}</label>
        <DatePicker
          dateFormat="dd.MM.yyyy"
          label={"test"}
          selected={dateFrom}
          onChange={setDateFrom}
          minDate={minDate}
        />
      </div>
      <div className="form-input-group">
        <label>{t("contact_form.to_date")}</label>
        <DatePicker
          dateFormat="dd.MM.yyyy"
          selected={dateTo}
          onChange={setDateTo}
          minDate={minDate}
        />
      </div>
      <div className="flex items-center gap-4">
        <label>{t("contact_form.contact_method")}</label>
        <div className="flex gap-4">
          <ContactMethod
            icon={<SvgWhatsApp className="w-10 h-10" />}
            onClick={() => {
              setContactMethod("whatsapp");
            }}
            isSelected={contactMethod === "whatsapp"}
          />
          <ContactMethod
            icon={<SvgViber className="w-10 h-10" />}
            onClick={() => {
              setContactMethod("viber");
            }}
            isSelected={contactMethod === "viber"}
          />
          <ContactMethod
            icon={<SvgTelegram className="w-10 h-10" />}
            onClick={() => {
              setContactMethod("telegram");
            }}
            isSelected={contactMethod === "telegram"}
          />
          <ContactMethod
            icon={<SvgPhone className="w-10 h-10" />}
            onClick={() => {
              setContactMethod("telefon");
            }}
            isSelected={contactMethod === "telefon"}
          />
        </div>
      </div>
      <PhoneInput
        className="mt-2"
        defaultCountry="MD"
        value={phone}
        onChange={setPhone}
        placeholder={t("contact_form.phone_placeholder")}
      />
      <div className="message-area">
        <textarea
          rows={4}
          placeholder={t("contact_form.message_placeholder")}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <button
        disabled={!isValid}
        onClick={handleSubmit}
        className={`submit-button ${isValid ? "" : "disabled"}`}
      >
        {t("content_form.contact_btn")}
      </button>
    </form>
  );
}

const ContactMethod = ({ icon, isSelected, onClick }) => (
  <div
    onClick={onClick}
    className={cn(
      "p-1 border rounded-lg cursor-pointer transition-all duration-300",
      {
        "border border-sky-600": isSelected
      }
    )}
  >
    {icon}
  </div>
);

export default Form;
