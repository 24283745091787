import React from 'react';
import './_cars-container.scss';
import {useContentfulCars} from '../../hooks/useCars';
import CarContainer from './CarContainer';

const CarsContainer = () => {
  const {data} = useContentfulCars();

  return (
    <div className="ric-cars-container">
      {data.map((_, index) => (
        <div className="card-wrapper" key={index}>
          <CarContainer index={index} />
        </div>
      ))}
    </div>
  );
};

export default CarsContainer;
