import React from 'react';
import {useTranslation} from 'react-i18next';
import './_car-price-list-table.scss';

const CarPricelistTable = ({data}) => {
  const {t} = useTranslation();

  return (
    <div className="price-list-table">
      <span className="price-list-table-cell">{t('price.days')}</span>
      <span className="price-list-table-cell">{t('price.value')}</span>
      {data.map(({days, price}) => (<>
        <span className="price-list-table-cell">{days}</span>
        <span className="price-list-table-cell">{price}</span>
      </>))}
    </div>
  )
};

export default CarPricelistTable;
