const LANG = 'lang';

const getLang = () => {
  const lang = localStorage.getItem(LANG) || '';

  if (['en', 'md', 'ru'].includes(lang.toLowerCase())) {
    return lang.toLowerCase();
  }

  return 'md';
};

const setLang = (newLang) => {
  localStorage.setItem(LANG, newLang);
};

export {
  getLang,
  setLang,
}
