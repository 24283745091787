import React from 'react';

const SvgCar = () => (
  <svg version="1.1" id="money" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="39.378px" height="39.378px" viewBox="0 0 39.378 39.378">
<g>
	<path d="M10.116,28.754c-1.023-0.297-2.017-0.734-2.943-1.316l3.341-3.342c0.011,0.006,0.022,0.008,0.032,0.014v-1.936
		c-0.353-0.217-0.688-0.473-0.992-0.777c-2.102-2.104-2.102-5.523,0-7.625c2.103-2.103,5.521-2.103,7.626,0
		c0.321,0.323,0.59,0.681,0.813,1.056c0.897-0.313,1.858-0.668,2.864-1.072l2.363-2.363c0.221,0.354,0.415,0.717,0.595,1.086
		c0.515-0.238,1.033-0.488,1.56-0.75c-0.633-1.3-1.476-2.521-2.553-3.598c-5.213-5.215-13.695-5.215-18.908,0
		c-5.217,5.213-5.217,13.698,0,18.911c1.773,1.772,3.93,2.93,6.205,3.498v-1.783h-0.002V28.754z M3.512,23.775
		c-2.365-3.761-2.37-8.584-0.02-12.354l3.342,3.344c-0.775,1.807-0.771,3.867,0.018,5.669L3.512,23.775z M19.559,7.73l-3.341,3.34
		c-1.826-0.797-3.917-0.791-5.74,0.017L7.14,7.749C10.918,5.356,15.775,5.352,19.559,7.73z M39.374,14.85L30.54,9.801
		c-9.293,5.908-18.986,7.744-18.986,7.744v3.729l0.89,0.519l-1.032,0.287v3.672l0.803,0.545l-1.233,0.399v3.584l8.547,4.88
		c10.153-1.664,19.276-7.689,19.276-7.689l-0.03-3.613l-0.544-0.43l0.976-0.574v-3.471l-0.631-0.545l0.803-0.459v-3.527
		L39.374,14.85L39.374,14.85z M21.954,15.789l5.918,3.41c-3.265,1.223-7.063,2.167-7.063,2.167l-5.902-3.253
		C14.907,18.111,20.411,16.371,21.954,15.789z M28.252,31.247c-4.623,1.797-8.446,2.723-8.446,2.723l-7.603-4.416v-1.922
		l7.603,4.129c2.713-0.43,5.745-1.471,8.514-2.629L28.252,31.247z M28.393,26.898c-4.438,1.695-8.045,2.568-8.045,2.568
		l-7.601-4.418v-1.922l7.601,4.131c2.583-0.412,5.453-1.373,8.112-2.463L28.393,26.898z M28.604,20.277l-0.066,2.105
		c-4.438,1.693-8.037,2.562-8.037,2.562L12.9,20.527v-1.92l7.601,4.129C23.083,22.327,25.946,21.368,28.604,20.277L28.604,20.277z
		 M30.648,11.317l5.958,3.519c-1.086,0.677-3.615,2.131-4.752,2.676l-5.742-3.51C28.51,12.828,30.648,11.317,30.648,11.317z
		 M37.401,26.684c-1.572,1.041-3.298,1.973-5.029,2.795l0.057-2.215c2.935-1.438,4.975-2.645,4.975-2.645L37.401,26.684
		L37.401,26.684z M37.944,22.18c-1.706,1.127-3.587,2.129-5.465,3l0.057-2.209c3.166-1.527,5.408-2.854,5.408-2.854V22.18z
		 M38.097,17.66c-1.718,1.135-3.612,2.144-5.503,3.018l0.057-2.208c3.187-1.535,5.446-2.875,5.446-2.875V17.66L38.097,17.66z
		 M22.135,16.457l4.359,2.58C26.497,19.037,21.476,19.897,22.135,16.457z M31.142,16.656l-4.002-2.352
		C30.376,14.123,31.142,16.656,31.142,16.656z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
);

export default SvgCar;
