import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class CarDetail extends Component {
  static propTypes = {
    svgIcon: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  render() {
    const {svgIcon, name, value} = this.props;

    return (
      <div className="ric-car-detail">
        {svgIcon}
        {name}

        <span className="detail-value">
          {value}
        </span>
      </div>
    );
  }
}
