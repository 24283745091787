import React from 'react';
import {createRoot} from 'react-dom/client';
import './scss/main.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as serviceWorker from './serviceWorker';
import Root from './component/Root';
import './i18n/i18n';
import {BrowserRouter} from 'react-router-dom';

const root = createRoot(
  document.getElementById('root'),
);

root.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
