import React from 'react';

const SvgEngine = () => (
  <svg version="1.1" id="engine" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 329.962 329.962" xmlSpace="preserve">
<g>
	<g>
		<g>
			<path d="M308.953,139.058c-11.582,0-21.003,9.428-21.003,21.009v6.2h-29.763l0.036-42.389
				c0-3.699-1.444-7.184-4.057-9.821c-2.631-2.619-6.116-4.057-9.816-4.057h-116.33c-4.929,0-9.523,2.655-12.005,6.934
				l-28.468,49.328h-5.818v-14.834c0-6.826-5.549-12.375-12.375-12.375c-6.82,0-12.363,5.555-12.363,12.375v14.834h-5.012v-14.834
				c0-6.826-5.549-12.375-12.363-12.375c-6.832,0-12.381,5.555-12.381,12.375v14.834h-5.334C9.827,166.261,0,176.082,0,188.153
				s9.821,21.898,21.898,21.898h5.334v20.514c0,6.832,5.555,12.375,12.381,12.375c6.82,0,12.363-5.549,12.363-12.375v-20.514h5.012
				v20.514c0,6.832,5.549,12.375,12.363,12.375c6.832,0,12.375-5.549,12.375-12.375v-20.514h2.524l31.762,55.009
				c2.482,4.278,7.077,6.934,12.005,6.934h77.528c4.953,0,9.559-2.655,12.017-6.934l31.768-55.009h38.624v17.071
				c0,11.582,9.416,21.009,21.003,21.009s21.003-9.428,21.003-21.009v-67.068C329.95,148.486,320.534,139.058,308.953,139.058z
				 M181.715,177.747l31.41,0.036l-87.498,60.993l-2.429-2.912l0.412-0.418l40.533-40.533h-31.732l68.428-51.697h24.578
				L181.715,177.747z"/>
		</g>
		<g>
			<path d="M147.161,85.684h17.065v10.537c0,7.321,5.961,13.276,13.276,13.276
				c7.309,0,13.264-5.955,13.264-13.276V85.684h17.083c7.644,0,13.867-6.212,13.867-13.849c0-7.644-6.223-13.867-13.867-13.867
				h-60.689c-7.644,0-13.867,6.223-13.867,13.867C133.294,79.473,139.517,85.684,147.161,85.684z"/>
		</g>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
);

export default SvgEngine;
