import React from 'react';

const SvgTransmission = () => (
  <svg version="1.1" id="transmission" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" xmlSpace="preserve">
<g>
	<g>
		<path d="M256,0C114.84,0,0,114.841,0,256s114.84,256,256,256s256-114.841,256-256S397.16,0,256,0z M256,491.52
			C126.135,491.52,20.48,385.866,20.48,256S126.135,20.48,256,20.48S491.52,126.134,491.52,256S385.865,491.52,256,491.52z"/>
	</g>
</g>
<g>
	<g>
		<path d="M389.12,92.16c-16.94,0-30.72,13.743-30.72,30.635v92.245h-30.72v-92.245c0-16.892-13.78-30.635-30.72-30.635
			c-16.94,0-30.72,13.743-30.72,30.635v92.245h-30.72v-92.245c0-16.892-13.78-30.635-30.72-30.635
			c-16.94,0-30.72,13.743-30.72,30.635v92.245h-30.72v-51.281c0-16.894-13.78-30.639-30.72-30.639
			c-16.94,0-30.72,13.745-30.72,30.639V276.48c0,5.655,4.585,10.24,10.24,10.24h81.92v102.486c0,16.891,13.78,30.634,30.72,30.634
			c16.94,0,30.72-13.743,30.72-30.634V286.72h30.72v102.486c0,16.891,13.78,30.634,30.72,30.634c16.94,0,30.72-13.743,30.72-30.634
			V286.72h30.72v102.486c0,16.891,13.78,30.634,30.72,30.634s30.72-13.743,30.72-30.634V122.795
			C419.84,105.903,406.06,92.16,389.12,92.16z M399.36,389.206c0,5.694-4.497,10.154-10.24,10.154
			c-5.648,0-10.24-4.555-10.24-10.154V276.48c0-5.655-4.585-10.24-10.24-10.24h-51.2c-5.655,0-10.24,4.585-10.24,10.24v112.726
			c0,5.694-4.497,10.154-10.24,10.154c-5.648,0-10.24-4.555-10.24-10.154V276.48c0-5.655-4.585-10.24-10.24-10.24h-51.2
			c-5.655,0-10.24,4.585-10.24,10.24v112.726c0,5.694-4.497,10.154-10.24,10.154c-5.648,0-10.24-4.555-10.24-10.154V276.48
			c0-5.655-4.585-10.24-10.24-10.24H102.4V163.759c0-5.696,4.497-10.159,10.24-10.159c5.648,0,10.24,4.558,10.24,10.159v61.521
			c0,5.655,4.585,10.24,10.24,10.24h51.2c5.655,0,10.24-4.585,10.24-10.24V122.795c0-5.695,4.497-10.155,10.24-10.155
			c5.648,0,10.24,4.555,10.24,10.155V225.28c0,5.655,4.585,10.24,10.24,10.24h51.2c5.655,0,10.24-4.585,10.24-10.24V122.795
			c0-5.695,4.497-10.155,10.24-10.155c5.648,0,10.24,4.555,10.24,10.155V225.28c0,5.655,4.585,10.24,10.24,10.24h51.2
			c5.655,0,10.24-4.585,10.24-10.24V122.795c0-5.695,4.497-10.155,10.24-10.155c5.648,0,10.24,4.555,10.24,10.155V389.206z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
);

export default SvgTransmission;
