import {PureComponent} from 'react';

export default class ScrollPosition extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getPosition();

    this.listener = () => {
      this.setState(this.getPosition());
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listener);
  }

  getPosition() {
    return {
      top: window.scrollY,
    };
  }

  render() {
    return this.props.children(this.state);
  }
}
