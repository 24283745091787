import React, {useCallback} from 'react';
import classNames from 'classnames';
import './_header.scss';
import {Link} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';

//OR import sass module
import {setLang} from 'i18n/lang';

const Header = ({isClear}) => {
  const {i18n} = useTranslation();
  const headerClasses = classNames('ric-header', {
    'state-clear': isClear,
  });

  const onLanguageChange = useCallback((newLanguage) => {
    i18n.changeLanguage(newLanguage.toLowerCase());
    setLang(newLanguage);
  }, [i18n]);

  return (
    <header>
      <div className={headerClasses}>
        <div className="header-lang-dropdown">
          <ReactFlagsSelect
            selected={i18n.language.toUpperCase()}
            onSelect={onLanguageChange}
            defaultCountry={i18n.language.toUpperCase()}
            showSelectedLabel={false}
            showOptionLabel={false}
            countries={["US", "MD", "RU"]}
          />
        </div>
        <div className="header-menu">
          <Link className="header-link" to="/">
            <Trans i18nKey="header.home"/>
          </Link>
          <Link className="header-link" to="/cars">
            <Trans i18nKey="header.ourcars"/>
          </Link>
          <Link className="header-link" to="/gallery">
            <Trans i18nKey="header.gallery"/>
          </Link>
          <a className="header-link" href="#contacts">
            <Trans i18nKey="header.contacts"/>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
