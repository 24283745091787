import React, {Component} from 'react';
import './_trait.scss';

export default class Trait extends Component {
  render() {
    const {title, description, icon} = this.props;

    return (
      <div className="ric-trait">
        {icon}
        <h2 className="item-title">
          {title}
        </h2>
        <div className="item-description">
          {description}
        </div>
      </div>
    );
  }
}
