import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './_button.scss';

export class Button extends Component {
  static propTypes = {
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    theme: PropTypes.string,
  };

  static defaultProps = {
    theme: 'red',
  };

  render() {
    const {onClick, className, text, theme} = this.props;

    return (
      <button
        className={`ric-button ${className || ''}`}
        onClick={onClick}
      >
        <span className={`theme-${theme}`}>
            {text}
        </span>
      </button>
    )
  }
}
