import React, {Component} from 'react';
import './_section.scss';

export default class Section extends Component {
  render() {
    const {title} = this.props;

    return (
      <div className="ric-section">
        <h2 className="section-title">{title}</h2>

        <div className="section-items">
          {this.props.children}
        </div>
      </div>
    );
  }
}
